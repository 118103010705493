import axios from "axios";
import {config} from '@/config';

/**
 * User login api call
 * @param email
 * @param password
 */
const login = (email: string, password: string) => {
    return axios.post(config.$api_url + 'login', {
        email: email,
        password: password,
    }, {
        headers: {
            Accept: 'application/json'
        },
    })
}

/**
 * User register api call
 *
 * @param email
 * @param name
 * @param password
 * @param confirm_password
 */
const register = (email: string, name: string, password: string, confirm_password: string) => {
    return axios.post(config.$api_url  + 'register', {
        email: email,
        name: name,
        password: password,
        confirm_password: confirm_password,
    },{
        headers: {
            Accept: 'application/json'
        },
    })
}

export {
    login, register
}