import { defineStore } from 'pinia'
import User from "@/models/User";
import {login, register} from "@/api/auth";

interface State {
    email: string
    name: string
    token: string | null
    intro: boolean
}

export const useUserStore = defineStore('user', {
    state: (): State => ({
        email: '',
        name: '',
        token: null,
        intro: true
    }),
    actions : {
        async createUser(email: string, name: string, password: string, confirm_password: string){
            try {
                const res = await register(email, name, password, confirm_password)
                this.updateUser(res.data.data)
                return res.data
            }catch (err: any){
                return err.response.data
            }
        },
        async loadUser(email: string, password: string){
            try {
                const res = await login(email, password)
                this.updateUser(res.data.data)
                return res.data
            }catch (err: any){
                return err.response.data
            }
        },
        updateUser (user: User) {
            this.email = user.email
            this.name = user.name
            this.token = user.token
        },
        clearUser () {
            this.$reset()
        },
        setIntro(state: boolean){
            this.intro = state
        }
    },
    persist: true
})