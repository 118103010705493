import { defineStore } from 'pinia'
import Restaurant from "@/models/Restaurant";
import {
    createRestaurant, deleteRestaurant,
    getNearbyRestaurants,
    getRestaurants,
    resetRestaurantCooldown, searchRestaurants,
    updateRestaurant
} from "@/api/restaurant";

interface State {
    restaurants: Restaurant[],
    nearbyRestaurants: any,
    searchRestaurants: any,
    nearbyRestaurantsRadius: number
}

export const useRestaurantStore = defineStore('restaurant', {
    // State
    state: (): State => ({
        restaurants: [],
        nearbyRestaurants: [],
        searchRestaurants: [],
        nearbyRestaurantsRadius: 1000
    }),

    // Getters
    getters: {
        cooldownRestaurants(state) {
            const restaurants = state.restaurants.filter((rest: Restaurant) => rest.cooldown_days_left > 0)
            return restaurants.sort((a: Restaurant, b: Restaurant) => a.cooldown_days_left - b.cooldown_days_left)
        },
        availableRestaurants(state) {
            return state.restaurants.filter((rest: Restaurant) => rest.cooldown_days_left < 1)
        },
        restaurant: (state) => (id: number) =>{
            return state.restaurants.find((restaurant: Restaurant) => restaurant.id === id)
        },
        nearbyRestaurant: (state) => (id: number) =>{
            return state.nearbyRestaurants.find((restaurant: any) => restaurant.id == id)
        },
        searchRestaurant: (state) => (id: number) =>{
            return state.searchRestaurants.find((restaurant: any) => restaurant.id == id)
        },
        findRestaurantByAddress: (state) => (address: string) =>{
            return state.restaurants.find((restaurant: any) => restaurant.address == address)
        },
    },

    // Actions
    actions : {
        async getRestaurants(){
            try {
                const res = await getRestaurants()
                this.restaurants = res.data.data
                return res.data
            }catch (err: any){
                return err.response.data
            }
        },
        async addRestaurant(restaurant: Restaurant) {
            try {
                const res = await createRestaurant(restaurant)
                this.restaurants.push(restaurant)
                return res.data
            } catch (err: any) {
                return err.response.data
            }
        },
        async updateRestaurant (restaurant: Restaurant) {
            try {
                const res = await updateRestaurant(restaurant.id, restaurant.cooldown)
                const index = this.restaurants.findIndex((rest: Restaurant) => rest.id == restaurant.id)
                this.restaurants.splice(index, 1, res.data.data.restaurant)
                return res.data
            } catch (err: any) {
                return err.response.data
            }
        },
        async deleteRestaurant (id: number){
            try {
                const res = await deleteRestaurant(id)
                const index = this.restaurants.findIndex((rest: Restaurant) => rest.id == id)
                this.restaurants.splice(index, 1)
                return res.data
            } catch (err: any) {
                return err.response.data
            }
        },
        updateNearbyRestaurants (restaurants: any[]) {
            this.nearbyRestaurants = restaurants
        },
        async resetCooldown (id: number){
            try {
                const res = await resetRestaurantCooldown(id)
                const index = this.restaurants.findIndex((rest: Restaurant) => rest.id == id)
                this.restaurants.splice(index, 1, res.data.data.restaurant)
                return res.data
            } catch (err: any) {
                return err.response.data
            }
        },
        clearRestaurants() {
            this.$reset()
        },
        async getNearbyRestaurants(lat: number, long: number, limit = 30){
            try {
                const res = await getNearbyRestaurants(lat, long, this.nearbyRestaurantsRadius, limit)
                this.updateNearbyRestaurants(res.data.results)
                return true
            }catch (err: any){
                return false
            }
        },
        async getSearchRestaurants(query: string, lat: number, long: number, radius = 5000, limit = 30){
            try {
                const res = await searchRestaurants(query, lat, long, radius, limit)
                this.searchRestaurants = res.data.results
                return true
            }catch (err: any){
                return false
            }
        },
        setNearbyRestaurantsRadius(radius: number){
            this.nearbyRestaurantsRadius = radius
        }
    },
    persist: true
})