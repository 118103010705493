import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {computed} from "vue";
import {useUserStore} from "@/stores/user";

async function guardAuth(to: any, from: any, next: any){
  const userStore = useUserStore()
  if (computed(() => userStore.token).value){
    next();
  }
  else {
    next('/register')
  }
}

async function guardNotAuth(to: any, from: any, next: any){
  const userStore = useUserStore()
  if (computed(() => userStore.token).value){
    next('/');
  }
  else {
    next()
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: guardAuth,
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Auth/RegisterView.vue'),
    beforeEnter: guardNotAuth
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/LoginView.vue'),
    beforeEnter: guardNotAuth
  },
  {
    path: '/nearby-search',
    name: 'nearbySearch',
    component: () => import(/* webpackChunkName: "nearbySearch" */ '../views/Restaurants/NearbySearch.vue'),
    beforeEnter: guardAuth,
  },
  {
    path: '/restaurant/:mode/:id',
    name: 'restaurant',
    component: () => import(/* webpackChunkName: "restaurant" */ '../views/Restaurants/Restaurant.vue'),
    beforeEnter: guardAuth,
  },
  {
    path: '/search-restaurant/',
    name: 'searchRestaurant',
    component: () => import(/* webpackChunkName: "search" */ '../views/Restaurants/Search.vue'),
    beforeEnter: guardAuth,
  },
  {
    path: '/my-restaurants/',
    name: 'myRestaurants',
    component: () => import(/* webpackChunkName: "my-restaurants" */ '../views/Restaurants/MyRestaurants.vue'),
    beforeEnter: guardAuth,
  },
  {
    path: '/profile/',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Auth/Profile.vue'),
    beforeEnter: guardAuth,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/Others/404.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
