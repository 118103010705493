import axios from "axios";
import {config} from '@/config';
import Restaurant from "@/models/Restaurant";
import {axiosBearer} from "@/main";

/**
 * Get restaurant api call
 */
const getRestaurants = () => {
    return axiosBearer.get(config.$api_url + 'restaurants', {
        headers: {
            Accept: 'application/json',
        },
    })
}

/**
 * Get nearby restaurant with tomtom api
 *
 * @param lat
 * @param long
 * @param radius
 * @param limit
 */
const getNearbyRestaurants = (lat: number, long: number, radius: number, limit: number) => {
    return axios.get(`https://api.tomtom.com/search/2/nearbySearch/.json?lat=${lat}&lon=${long}&radius=${radius}
    &language=fr-FR&categorySet=7315%2C%207315015&view=Unified&relatedPois=off&key=${config.$tomtom_api_key}&limit=${limit}`, {
        headers: {
            Accept: 'application/json',
        },
    })
}

/**
 * Search restaurant by name
 *
 * @param query
 * @param lat
 * @param long
 * @param radius
 * @param limit
 */
const searchRestaurants = (query: string, lat: number, long: number, radius = 5000, limit = 10) => {
    return axios.get(`https://api.tomtom.com/search/2/poiSearch/${encodeURI(query)}.json?limit=${limit}&lat=${lat}&lon=${long}&radius=${radius}
    &categorySet=7315&view=Unified&relatedPois=off&key=${config.$tomtom_api_key}&relatedPois=all`, {
        headers: {
            Accept: 'application/json',
        },
    })
}

/**
 * Add new restaurant to user restaurants list
 *
 * @param restaurant
 */
const createRestaurant = (restaurant: Restaurant) => {
    return axiosBearer.post(config.$api_url + 'restaurants/store', {
        name: restaurant.name,
        address: restaurant.address,
        cooldown: restaurant.cooldown,
        image: restaurant.image,
        categories: restaurant.categories,
        latitude: restaurant.latitude,
        longitude: restaurant.longitude,
    }, {
        headers: {
            Accept: 'application/json',
        },
    })
}

/**
 * Reset restaurant cooldown
 *
 * @param id
 */
const resetRestaurantCooldown = (id: number) => {
    return axiosBearer.patch(config.$api_url + 'restaurants/reset-cooldown', {
        id: id,
    }, {
        headers: {
            Accept: 'application/json',
        },
    })
}

/**
 * Update restaurant api call
 *
 * @param id
 * @param cooldown
 */
const updateRestaurant = (id: number, cooldown: number) => {
    return axiosBearer.patch(config.$api_url + 'restaurants/update', {
        id: id,
        cooldown: cooldown
    }, {
        headers: {
            Accept: 'application/json',
        },
    })
}

/**
 * Delete restaurant api call
 *
 * @param id
 */
const deleteRestaurant = (id: number) => {
    return axiosBearer.post(config.$api_url + 'restaurants/delete', {
        id: id,
    }, {
        headers: {
            Accept: 'application/json',
        },
    })
}


export {
    getRestaurants, getNearbyRestaurants, createRestaurant, searchRestaurants, resetRestaurantCooldown,
    updateRestaurant, deleteRestaurant
}